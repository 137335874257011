import { Button, Input, SVG, StaticAlert } from "components";
import { SVG_TYPE } from "enums";
import { FieldArray, Field } from "formik";
import "./door-sills.scss";

interface DoorSillsType {
  field: any;
  errors?: any;
  form: any;
  validateField: any;
  touched: any;
}

const DoorSills = ({ errors, form, touched, field }: DoorSillsType) => {
  const hasError = () => {
    if (Array.isArray(field.name)) {
      return field.name.some((n: any) => errors[n]);
    }
    return errors[field.name];
  };
  return (
    <div className="hole-dimension door-sills">
      <div>
        <FieldArray
          name="skirtingBoards"
          render={(arrayHelpers) => (
            <div>
              <div className="am-section-heading door-sills__heading">
                <h3>
                  Listwy progowe <span>(opcjonalnie)</span>
                </h3>
                {form.values?.skirtingBoards.length === 0 && (
                  <Button
                    size="small"
                    stroke
                    label="Dodaj listwę"
                    onClick={() => {
                      arrayHelpers.push({
                        type: "",
                        color: "",
                        length: "",
                        amount: "",
                      });
                    }}
                  />
                )}
              </div>
              {form.values?.skirtingBoards.map(
                (skirtingBoard: any, index: any) => (
                  <div key={index}>
                    {form.values?.skirtingBoards?.length > index && (
                      <div className="door-sills__delete-wrapper">
                        <div
                          className="door-sills__delete"
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          <SVG type={SVG_TYPE.BIN} />
                          Usuń tę listwę
                        </div>
                      </div>
                    )}
                    <Field
                      errors={errors}
                      key={`type-${index}`}
                      label={`Rodzaj`}
                      maxLength={30}
                      name={`skirtingBoards.${index}.type`}
                      component={Input}
                    />
                    <Field
                      errors={errors}
                      key={`color-${index}`}
                      maxLength={30}
                      label={`Kolor`}
                      name={`skirtingBoards.${index}.color`}
                      component={Input}
                    />
                    <Field
                      errors={errors}
                      key={`length-${index}`}
                      label={`Długość`}
                      type="number"
                      maxLength={6}
                      rightPlaceholder="mm"
                      name={`skirtingBoards.${index}.length`}
                      component={Input}
                    />
                    <Field
                      errors={errors}
                      key={`amount-${index}`}
                      label={`Ilość`}
                      type="number"
                      maxLength={4}
                      rightPlaceholder="szt."
                      name={`skirtingBoards.${index}.amount`}
                      component={Input}
                    />
                    {form.values?.skirtingBoards?.length - 1 > index && (
                      <div className="am-separator" />
                    )}
                  </div>
                )
              )}
              {form.values?.skirtingBoards.length > 0 && (
                <Button
                  stroke
                  label="Dodaj kolejną"
                  size="small"
                  className="door-sills__add-button"
                  onClick={() => {
                    arrayHelpers.push({
                      type: "",
                      color: "",
                      length: "",
                      amount: "",
                    });
                  }}
                />
              )}
              {errors && touched && (
                <>
                  <StaticAlert
                    size="small"
                    show={hasError()}
                    label="Te dane są obowiązkowe. Uzupełnij je, aby móc przejść dalej."
                  />
                </>
              )}
              <div className="am-separator" />
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default DoorSills;
