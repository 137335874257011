import { useState } from "react";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import {
  SingleSectionElement,
  TypeOfRadiobox,
} from "features/addingMeasurement";
import { Button, Textarea, Select, Input } from "components";
import { VALIDATION_MESSAGES } from "enums";
import { usePutFloorInstallationInfo } from "hooks/measurements";
import { useSearchParams } from "react-router-dom";
import { ScrollToError } from "utils";

interface BlindsAndShuttersMeasurementValuesType {
  measurementUuid: string | null;
  measurementInfo: any;
  step: number;
  setStep(step: number): void;
  prevStep: () => void;
}

const BlindsAndShuttersMeasurementValues = ({
  measurementUuid,
  measurementInfo,
  setStep,
  step,
  prevStep,
}: BlindsAndShuttersMeasurementValuesType) => {
  const [isValidated, setIsValidated] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const { mutate: editInstallationInfo } = usePutFloorInstallationInfo({
    onSuccess: () => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("measurementPage", "4");
      setSearchParams(newSearchParams, { replace: true });
      setStep(step + 1);
    },
  });

  const validationSchema = Yup.object().shape({
    screedHumidity: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    airHumidity: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    numberOfAssemblyTeams: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    plannedAssemblyTime: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    typesOfOldWindows: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  });

  const handleOnSubmit = (values: any) => {
    setStep(step + 1);
    editInstallationInfo(values);
  };

  return (
    <div>
      <div className="am-heading">Wartości pomiaru</div>
      <Formik
        initialValues={{
          measurementUuid: measurementUuid,
          activities: [],
          cableExitSide: "",
        }}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={isValidated}
        onSubmit={async (values) => handleOnSubmit(values)}
      >
        {({ handleSubmit, errors, submitCount, touched }) => {
          if (submitCount > 0) {
            setIsValidated(true);
          }
          return (
            <form className="amfs__form form" onSubmit={handleSubmit}>
              <ScrollToError />
              <SingleSectionElement
                touched={touched}
                name="asdas"
                errors={errors}
                className="padding-top-separator static-alert-top"
              >
                <Field
                  label="Rodzaj produktu"
                  name="asdas"
                  white
                  type="number"
                  rightPlaceholder="mm"
                  options={[
                    { value: "interior", label: "Rolety" },
                    { value: "external", label: "Żaluzje" },
                  ]}
                  component={Select}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="asdas"
                errors={errors}
                className="padding-top-separator static-alert-top"
              >
                <Field
                  label="Typ produktu"
                  name="asdas"
                  white
                  type="number"
                  rightPlaceholder="mm"
                  options={[
                    { value: "interior", label: "Standardowa" },
                    { value: "external", label: "Podtynkowa" },
                  ]}
                  component={Select}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="color"
                errors={errors}
                className="padding-top-separator single-section-element--strips"
              >
                <Field optional label="Kolor" name="color" component={Input} />
              </SingleSectionElement>
              <SingleSectionElement
                className="padding-top-separator single-section-element--strips"
                touched={touched}
                name="width"
                errors={errors}
              >
                <Field
                  label="Szerokość"
                  name="width"
                  type="number"
                  rightPlaceholder="mm"
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                className="padding-top-separator single-section-element--strips"
                touched={touched}
                name="height"
                errors={errors}
              >
                <Field
                  label="Wysokość"
                  name="height"
                  type="number"
                  rightPlaceholder="mm"
                  component={Input}
                />
              </SingleSectionElement>
              <Field
                errors={errors}
                name="cableExitSide"
                label="Strona wyjścia kabla"
                options={[
                  {
                    label: "Bok",
                    value: "side",
                    uuid: "side",
                  },
                  {
                    label: "Tył",
                    value: "back",
                    uuid: "back",
                  },
                  {
                    label: "Przód",
                    value: "front",
                    uuid: "front",
                  },
                ]}
                component={TypeOfRadiobox}
              />
              <Field
                errors={errors}
                name="cableExitSide"
                label="Typ montażu"
                options={[
                  {
                    label: "We wnęce",
                    value: "side",
                    uuid: "side",
                  },
                  {
                    label: "Na ścianie na zewnątrz",
                    value: "back",
                    uuid: "back",
                  },
                  {
                    label: "Na ścianie wewnątrz",
                    value: "front",
                    uuid: "front",
                  },
                ]}
                component={TypeOfRadiobox}
              />
              <Field
                errors={errors}
                name="cableExitSide"
                label="Rodzaj napędu"
                half
                options={[
                  {
                    label: "Radiowy",
                    value: "side",
                    uuid: "side",
                  },
                  {
                    label: "Radiowy + klawisz",
                    value: "back",
                    uuid: "back",
                  },
                  {
                    label: "Ręczny",
                    value: "front",
                    uuid: "front",
                  },
                  {
                    label: "Klawisz",
                    value: "front",
                    uuid: "front",
                  },
                  {
                    label: "Klawisz zewnętrzny",
                    value: "front",
                    uuid: "front",
                  },
                  {
                    label: "Wifi",
                    value: "front",
                    uuid: "front",
                  },
                ]}
                component={TypeOfRadiobox}
              />
              <SingleSectionElement
                touched={touched}
                name="asdas"
                errors={errors}
                className="padding-top-separator static-alert-top"
              >
                <Field
                  label="Strona kabla zasilającego/linki (widok od wewnątrz)"
                  name="asdas"
                  white
                  type="number"
                  rightPlaceholder="mm"
                  options={[
                    { value: "interior", label: "Prawa" },
                    { value: "external", label: "Lewa" },
                  ]}
                  component={Select}
                />
              </SingleSectionElement>
              <Field
                className="am-margin-top"
                errors={errors}
                label="Dodatkowe uwagi"
                name="comments"
                optional
                component={Textarea}
                maxLength={250}
              />
              <div className="am__buttons">
                <Button
                  stroke
                  onClick={() => prevStep()}
                  label="Poprzedni krok"
                />
                <Button onClick={() => handleSubmit()} label="Dalej" />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default BlindsAndShuttersMeasurementValues;
