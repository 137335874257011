// utils.ts

interface MeasurementData {
  measurement: {
    uuid: string;
    lastFinishedPage: number;
  };
}

const handleMeasurementSuccess = (
  data: MeasurementData,
  searchParams: URLSearchParams,
  setSearchParams: (
    params: URLSearchParams,
    options?: { replace?: boolean }
  ) => void,
  setStep: (step: number) => void
) => {
  const newSearchParams = new URLSearchParams(searchParams);
  newSearchParams.set("measurementUuid", data.measurement.uuid);
  newSearchParams.set(
    "measurementPage",
    (data.measurement.lastFinishedPage + 1).toString() || "2"
  );
  setSearchParams(newSearchParams, { replace: true });
  setStep(data.measurement.lastFinishedPage + 1 || 2);
};

export default handleMeasurementSuccess;
