import { useState, useEffect } from "react";
import { StaticAlert, Select, Input } from "components";
import { Field } from "formik";
import "./spout-level.scss";

interface SpoutLevelType {
  field: any;
  errors?: any;
  form: any;
  touched?: any;
  setTypeOfInstallationUuids: () => void;
  typeOfInstallationUuids: any;
  name: string;
}

const spoutLevelOptions = [
  {
    label: "Z projektu",
    value: "fromTheProject",
  },
  {
    label: "Określony przez inwestora",
    value: "specifiedByInvestor",
  },
  {
    label: "Określony przez wykonawcę",
    value: "specifiedByContractor",
  },
  {
    label: "Zmierzony od poziomu schodów",
    value: "measuredFromStairLevel",
  },
];

const typeOfStarsOptions = [
  {
    label: "Betonowe",
    value: "concrete",
  },
  {
    label: "Samonośne",
    value: "selfSupporting",
  },
];

const SpoutLevel = ({
  errors,
  form,
  field,
  setTypeOfInstallationUuids,
  typeOfInstallationUuids,
}: SpoutLevelType) => {
  const initialSpoutLevel = form.values.spoutLevel;
  const initialTypeOfStars = form.values.typeOfStars;
  const [spountLevelType, setSpoutLevelType] = useState(
    initialSpoutLevel || ""
  );

  useEffect(() => {
    if (initialSpoutLevel) {
      setSpoutLevelType(initialSpoutLevel);
    }
  }, [initialSpoutLevel]);

  const isSpoutMeasuredFromStairLevel =
    spountLevelType === "measuredFromStairLevel";
  return (
    <div className="spout-level">
      <Field
        label="Wartość wylewki"
        placeholder="Wybierz"
        name="spoutLevel"
        white
        component={Select}
        options={spoutLevelOptions}
        onChange={(e: any) => setSpoutLevelType(e.value)}
      />
      {isSpoutMeasuredFromStairLevel && (
        <>
          <Field
            value={initialTypeOfStars || ""}
            label="Rodzaj schodów"
            placeholder="Wybierz"
            name="typeOfStars"
            white
            component={Select}
            options={typeOfStarsOptions}
          />
          <Field
            label="Grubość okładziny na schodach"
            name="starsMeasurement"
            component={Input}
            rightPlaceholder="mm"
            type="number"
            options={spoutLevelOptions}
          />
        </>
      )}
      {errors && form.touched && (
        <>
          <StaticAlert
            className="am-alert"
            size="small"
            show={errors[field.name] && errors[field.name]}
            label="Te dane są obowiązkowe. Uzupełnij je, aby móc przejść dalej."
          />
        </>
      )}
      <div className="am-separator" />
    </div>
  );
};

export default SpoutLevel;
