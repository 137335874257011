/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { DateRangePicker, SkeletonRow, SelectWithFetch, SVG } from "components";
import { useGetOrdersTime, useGetOrderTimes } from "hooks/dashboard";
import moment from "moment";
import "moment/locale/pl";
import classnames from "classnames";
import { EmployeeTimeByDate } from "features/statistics";
import { Formik, Field } from "formik";
import {
  OrdersTimeType,
  DayTimeType,
  UserWorkTimeType,
} from "types/StatisticTypes";
import "./orders-time-stats.scss";
import { SVG_TYPE } from "enums";
import axiosInstance from "axiosConfig";

const OrdersTimeStats = () => {
  const selectAll = { label: "Wszystkie zlecenia", value: "all" };
  const [periodTime, setPeriodTime] = useState("currentMonth");
  const [selectedUser, setSelectedUser] = useState({ orderId: 0, date: "" });
  const [selectedOrder, setSelectedOrder] = useState<any>([selectAll]);
  const [clientName, setClientName] = useState("");
  const period = periodTime;
  const { data: userTimes, isLoading } = useGetOrdersTime(
    period,
    selectedOrder,
    clientName
  );

  const tableData = userTimes?.length > 0 && userTimes;
  const { data: workTime } = useGetOrderTimes(
    selectedUser.orderId,
    selectedUser.date
  );
  const isGoBackActive = tableData.length === 2;

  const handleSetUser = (orderId: any, date: string) => {
    setSelectedUser({ orderId: orderId, date: date });
  };

  const handleOnCloseEmployeeTime = () => {
    setSelectedUser({ orderId: 0, date: "" });
  };
  const isOpenEmployeeTime = selectedUser.orderId !== 0;

  const reportsData = tableData.length === 2 && tableData[1].reports;

  const formatTime = (time: string) => {
    const [hours, minutes] = time.split(":").map(Number);
    const formattedHours = hours > 0 ? `${hours}h` : "";
    const formattedMinutes = minutes > 0 ? `${minutes}m` : "";
    return `${formattedHours} ${formattedMinutes}`.trim();
  };

  const handleChangeDate = (period: string) => {
    setSelectedOrder([selectAll]);
    setPeriodTime(period);
  };

  const handleClickJob = (test: any) => {
    setSelectedOrder([{ value: test }]);
  };

  const fetchOrderOptions = async (inputValue: string) => {
    const response = await axiosInstance.get(
      `/search-clients?query=${inputValue}`
    );
    const orders = await response.data.clients;
    return orders;
  };

  const handleGoToAllJobs = () => {
    setClientName("");
    setSelectedOrder([selectAll]);
  };

  return (
    <>
      <EmployeeTimeByDate
        times={workTime}
        isOpen={isOpenEmployeeTime}
        onClose={handleOnCloseEmployeeTime}
      />
      <div className="employees-time-stats">
        <div className="employees-time-stats__selects employees-time-stats__orders">
          <Formik
            initialValues={{
              client: "",
            }}
            enableReinitialize
            onSubmit={() => console.log(null)}
          >
            {({ setFieldValue, values, resetForm }) => {
              if (clientName === "" && values.client !== "") {
                setFieldValue("client", "Wszyscy klienci");
                resetForm();
              }

              return (
                <>
                  <Field
                    name="client"
                    component={SelectWithFetch}
                    white
                    size="medium"
                    onCloseChoice={() => handleGoToAllJobs()}
                    loadOptions={fetchOrderOptions}
                    placeholder="Wyszukaj klienta"
                    onChange={(selectedOption: any) => {
                      setClientName(selectedOption.value);
                      setSelectedOrder([selectAll]);
                    }}
                  />
                  <DateRangePicker
                    label="Obecny miesiąc"
                    onChangeDate={(period: string) => handleChangeDate(period)}
                  />
                </>
              );
            }}
          </Formik>
        </div>
        {isLoading ? (
          <SkeletonRow count={12} height={58} />
        ) : (
          <>
            {(isGoBackActive || clientName !== "") && (
              <div className="ets__go-back" onClick={() => handleGoToAllJobs()}>
                <SVG type={SVG_TYPE.ARROW_BACK} /> Wróć
              </div>
            )}
            <div className="ets__table-wrapper">
              <div className="ets__table ets__table--employee-time">
                <div className="ets__table-row">
                  <div className="ets__table-heading ets__table-heading--employee">
                    Zlecenie
                  </div>
                  <div className="ets__table-heading ets__table-heading--time">
                    Czas
                  </div>
                </div>
                {tableData &&
                  tableData.map(
                    (
                      { orderName, totalTime, orderId }: OrdersTimeType,
                      index: number
                    ) => {
                      const isLastElement = index === tableData.length - 1;
                      return (
                        <div key={index} className="ets__table-row">
                          <div
                            className={`ets__table-body ets__table-body--employee ets__table-body--job ${
                              !isLastElement ? "ets__table-body--job-entry" : ""
                            }`}
                            onClick={() =>
                              !isLastElement && handleClickJob(orderId)
                            }
                          >
                            <div
                              className={`user  ${
                                isLastElement && tableData.length > 2
                                  ? "totaled"
                                  : ""
                              }`}
                            >
                              {orderName}
                            </div>
                          </div>

                          <div className="ets__table-body ets__table-body--time">
                            {totalTime}
                          </div>
                        </div>
                      );
                    }
                  )}
              </div>

              <div className="ets__table ets__table--days">
                <div className="ets__table-row">
                  <div className="ets__table-heading ets__table-heading--empty"></div>
                  {tableData?.[0]?.days?.length > 0 &&
                    tableData[0].days.map(
                      ({ day, date }: DayTimeType, index: number) => {
                        const now = moment().format("DD/MM/YYYY");
                        const isToday = now === date;
                        const lastElement =
                          index === tableData[0].days.length - 1;
                        const dayNumber = day.toString().padStart(2, "0");
                        const dayName = moment(date, "DD/MM/YYYY").format("dd");
                        const isWeekend = dayName === "So" || dayName === "Nd";
                        const dayClass = classnames("ets__table-heading", {
                          "ets__table-heading--day": true,
                          "ets__table-body--today": isToday,
                          "ets__table-heading--weekend": isWeekend,
                          "ets__table-heading--last-element": lastElement,
                        });
                        return (
                          <div key={index} className={dayClass}>
                            <span className="day-number">{dayNumber}</span>
                            <span className="day-name">{dayName}</span>
                          </div>
                        );
                      }
                    )}
                </div>

                {tableData &&
                  tableData.map(({ days }: UserWorkTimeType, index: number) => {
                    const isLastElement = index === tableData.length - 1;
                    const onlyOne = tableData.length === 2;
                    return (
                      <div key={index} className="ets__table-row">
                        <div className="ets__table-body ets__table-body--empty"></div>
                        {days?.length > 0 &&
                          days.map(
                            (
                              { countedTime, date, day, orderId }: DayTimeType,
                              dayIndex: number
                            ) => {
                              const isSummaryRow = orderId === "all";
                              const now = moment().format("DD/MM/YYYY");
                              const isToday = now === date;
                              const dayName = moment(date, "DD/MM/YYYY").format(
                                "dd"
                              );
                              const isWeekend =
                                dayName === "So" || dayName === "Nd";
                              const isEnd = dayIndex === days.length - 1;
                              const dayClass = classnames("ets__table-body", {
                                "ets__table-body--day": true,
                                "ets__table-body--weekend": isWeekend,
                                "ets__table-body--today": isToday,
                                "ets__table-body--last-element":
                                  isLastElement && dayIndex === 0,
                                "ets__table-body--last-elements": isLastElement,
                                "ets__table-body--last-elements--only-one":
                                  onlyOne && !isSummaryRow,
                                "ets__table-body--ended": isEnd,
                              });
                              const hasTime = countedTime !== "00:00";

                              return (
                                <div key={dayIndex} className={dayClass}>
                                  <span
                                    className={`day-number ${
                                      hasTime ? "day-number--with-time " : ""
                                    }`}
                                    onClick={() =>
                                      hasTime
                                        ? handleSetUser(orderId, date)
                                        : null
                                    }
                                  >
                                    {countedTime}
                                  </span>
                                </div>
                              );
                            }
                          )}
                      </div>
                    );
                  })}
              </div>
            </div>
          </>
        )}
      </div>
      {reportsData && (
        <div className="job-report">
          <div className="job-report__heading">Raport kosztów</div>
          <div className="job-report__list">
            {reportsData.length > 0 &&
              reportsData.map((item: any, index: number) => {
                const isLastElement = index === reportsData.length - 1;
                const isUsedMaterial = item.activity === "Zużyte materiały";
                return (
                  <div className="job-report__item">
                    <div className="job-report__item-name">{item.activity}</div>
                    {!isLastElement && (
                      <div className="job-report__item-headings">
                        <div className="job-report__item-heading">
                          {isUsedMaterial ? "Materiał" : "Pracownik"}
                        </div>
                        <div className="job-report__item-heading">
                          {isUsedMaterial ? "Ilość" : "Czas"}
                        </div>
                        <div className="job-report__item-heading">
                          {!isUsedMaterial && "Stawka"}
                        </div>
                        <div className="job-report__item-heading">Koszt</div>
                      </div>
                    )}
                    {item.users.length > 0 &&
                      item.users.map((user: any) => {
                        return (
                          <div className="job-report__person">
                            <div
                              className={`job-report__person-label ${
                                isLastElement || isUsedMaterial
                                  ? ""
                                  : "job-report__person--user"
                              }`}
                            >
                              <div
                                style={{ backgroundColor: user.userColor }}
                              />
                              {user.name}
                            </div>
                            <div className="job-report__person-label">
                              {!isLastElement && formatTime(user.userTime)}
                              {isUsedMaterial && user.rate}
                            </div>
                            <div className="job-report__person-label">
                              {isUsedMaterial
                                ? null
                                : `${
                                    !isLastElement ? `${user.rate} zł/h` : ""
                                  }`}
                            </div>
                            <div className={`job-report__person-label`}>
                              {user.userEarnings} zł
                            </div>
                          </div>
                        );
                      })}
                    <div className="job-report__person job-report__person--total">
                      <div className="job-report__person-label job-report__person--user">
                        Razem
                      </div>
                      <div className="job-report__person-label"></div>
                      <div className="job-report__person-label"></div>
                      <div className="job-report__person-label job-report__person-label--total">
                        {item.totalActivityCost} zł
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
};

export default OrdersTimeStats;
