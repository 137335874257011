import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';
import { addEmployeeType } from 'types/UsersTypes';

type EditEmployeeType = addEmployeeType & {
  uuid: string;
};

const useEditEmployee = (
  options?: UseMutationOptions<any, Error, EditEmployeeType, any>
) => {
  return useMutation({
    ...options,
    mutationKey: ['editEmployee'],
    mutationFn: async ({
      uuid,
      phoneNumber,
      firstName,
      lastName,
      role,
      phonePrefixUuid,
      locationUuid,
      userColor,
    }) => {
      return axiosInstance.put(`/users/${uuid}`, {
        phoneNumber,
        firstName,
        lastName,
        role,
        phonePrefixUuid,
        locationUuid,
        userColor,
      }).then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie edycji...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'edit-employee',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Informacje o pracowniku zostały zaktualizowane',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'edit-employee',
      });
      queryClient.invalidateQueries({ queryKey: ['users'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error: any, variables, context) => {
      console.log(error.data.message)
      if(error.data.message === 'PHONE_NUMBER_EXIST') {
        Toast({
          message: 'Numer telefonu zajęty',
          type: TOAST_TYPES_ENUM.ERROR,
          id: 'edit-employee',
        });
      } else {
        Toast({
          message: 'Błąd podczas edytowania informacji o pracowniku',
          type: TOAST_TYPES_ENUM.ERROR,
          id: 'edit-employee',
        });
      }
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useEditEmployee;
