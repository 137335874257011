import { Button, Input, SVG, StaticAlert } from "components";
import { SVG_TYPE } from "enums";
import { FieldArray, Field } from "formik";
import "./door-sills.scss";

interface SkirtingBoardsType {
  field: any;
  errors?: any;
  form: any;
  validateField: any;
}

const SkirtingBoards = ({ errors, form }: SkirtingBoardsType) => {
  return (
    <div className="hole-dimension door-sills skirtingBoards">
      <div>
        <FieldArray
          name="skirtingBoards"
          render={(arrayHelpers) => (
            <div>
              <div className="am-section-heading door-sills__heading">
                <h3>Listwy przypodłogowe</h3>
                {form.values?.skirtingBoards.length === 0 && (
                  <Button
                    size="medium"
                    stroke
                    label="Dodaj listwę"
                    onClick={() => {
                      arrayHelpers.push({
                        type: "",
                        color: "",
                        length: "",
                        amount: "",
                      });
                    }}
                  />
                )}
              </div>
              {form.values?.skirtingBoards.map((_: any, index: any) => (
                <div key={index}>
                  <div className="door-sills__delete-wrapper">
                    <div
                      className="door-sills__delete"
                      onClick={() => arrayHelpers.remove(index)}
                    >
                      <SVG type={SVG_TYPE.BIN} />
                      Usuń tę listwę
                    </div>
                  </div>
                  <Field
                    errors={errors}
                    label={`Rodzaj`}
                    maxLength={30}
                    optional
                    name={`skirtingBoards.${index}.type`}
                    component={Input}
                  />
                  <Field
                    errors={errors}
                    maxLength={30}
                    label={`Kolor`}
                    optional
                    name={`skirtingBoards.${index}.color`}
                    component={Input}
                  />
                  <Field
                    errors={errors}
                    label={`Długość`}
                    type="number"
                    maxLength={6}
                    rightPlaceholder="mm"
                    name={`skirtingBoards.${index}.length`}
                    component={Input}
                  />
                  <Field
                    errors={errors}
                    label={`Zapas`}
                    type="number"
                    maxLength={4}
                    rightPlaceholder="%"
                    name={`skirtingBoards.${index}.amount`}
                    component={Input}
                  />
                  {form.values?.skirtingBoards?.length > index + 1 && (
                    <div className="am-separator" />
                  )}
                </div>
              ))}
              {form.values?.skirtingBoards.length > 0 && (
                <Button
                  stroke
                  label="Dodaj listwę"
                  size="medium"
                  className="door-sills__add-button"
                  onClick={() => {
                    arrayHelpers.push({
                      type: "",
                      color: "",
                      length: "",
                      amount: "",
                    });
                  }}
                />
              )}
              {errors && form.touched && (
                <>
                  <StaticAlert
                    className="am-alert"
                    size="small"
                    show={errors["skirtingBoards"]}
                    label="Te dane są obowiązkowe. Uzupełnij je, aby móc przejść dalej."
                  />
                </>
              )}
              <div className="am-separator" />
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default SkirtingBoards;
