/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import "./building-material-component.scss";
import { StaticAlert, CheckboxPart } from "components";

interface TypeOfRadioboxType {
  field: any;
  errors?: any;
  form: any;
  label: string;
  half: boolean;
  options: { label: string; value: string; uuid: string }[];
}

const TypeOfRadiobox = ({
  field,
  form,
  errors,
  label,
  options,
  half = false,
}: TypeOfRadioboxType) => {
  const [buildingMaterialObject, setBuildingMaterialObject] = useState(
    form.values[field.name] || ""
  );

  // useEffect(() => {
  //   setBuildingMaterialObject(form.values[field.name]);
  // }, [form.values[field.name]]);

  const handleCheckMaterial = (item: any) => {
    if (buildingMaterialObject === item.value) {
      setBuildingMaterialObject("");
    } else {
      setBuildingMaterialObject(item.value);
    }
  };

  useEffect(() => {
    form.setFieldValue(field.name, buildingMaterialObject);
  }, [buildingMaterialObject]);

  return (
    <div className="building-material-component">
      <div className="am-section-heading">{label}</div>
      <div
        className={`am-section-checkbox-list construction-site__list ${
          half ? "construction-site__list--half" : ""
        }`}
      >
        {options.map((item: any) => {
          return (
            <CheckboxPart
              className="radiobox"
              onChange={() => handleCheckMaterial(item)}
              checked={buildingMaterialObject === item.value}
              key={item.value}
              label={item.label}
              uuid={item.value}
            />
          );
        })}
      </div>
      {errors && form.touched && (
        <>
          <StaticAlert
            className="am-alert"
            size="small"
            show={errors[field.name] && errors[field.name]}
            label="Te dane są obowiązkowe. Uzupełnij je, aby móc przejść dalej."
          />
        </>
      )}
      <div className="am-separator" />
    </div>
  );
};

export default TypeOfRadiobox;
