import { useState } from "react";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import {
  SingleSectionElement,
  ConstructionSite,
  BuildingMaterialComponent,
  MountingAccessories,
} from "features/addingMeasurement";
import { Button, Input, Textarea, Select } from "components";
import { VALIDATION_MESSAGES } from "enums";
import { usePutRollerBlindsInstallationInfo } from "hooks/measurements";
import { useSearchParams } from "react-router-dom";
import { ScrollToError } from "utils";

interface BlindsAndShuttersInstallationInformationType {
  measurementUuid: string | null;
  measurementInfo: any;
  step: number;
  setStep(step: number): void;
  prevStep: () => void;
}

const BlindsAndShuttersInstallationInformation = ({
  measurementUuid,
  measurementInfo,
  setStep,
  step,
  prevStep,
}: BlindsAndShuttersInstallationInformationType) => {
  const [isValidated, setIsValidated] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const initialMeasurementInstallationInfo =
    measurementInfo?.measurementInstallationInfo;

  const { mutate: editInstallationInfo } = usePutRollerBlindsInstallationInfo({
    onSuccess: () => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("measurementPage", "4");
      setSearchParams(newSearchParams, { replace: true });
      setStep(step + 1);
    },
  });

  const validationSchema = Yup.object().shape({
    screedHumidity: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    airHumidity: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    numberOfAssemblyTeams: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    plannedAssemblyTime: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    typesOfOldWindows: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  });

  const handleOnSubmit = (values: any) => {
    setStep(step + 1);
    editInstallationInfo(values);
  };

  return (
    <div>
      <div className="am-heading">Informacje montażowe</div>
      <Formik
        initialValues={{
          measurementUuid: measurementUuid,
          constructionSiteUuids: [],
          numberOfAssemblyTeams:
            initialMeasurementInstallationInfo?.numberOfAssemblyTeams || "",
          plannedAssemblyTime:
            initialMeasurementInstallationInfo?.plannedAssemblyTime || "",
          comments: initialMeasurementInstallationInfo?.comments || "",
          installationElementsUuids: [],
        }}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={isValidated}
        onSubmit={async (values) => handleOnSubmit(values)}
      >
        {({ handleSubmit, errors, values, submitCount, touched }) => {
          if (submitCount > 0) {
            setIsValidated(true);
          }
          return (
            <form className="amfs__form form" onSubmit={handleSubmit}>
              <ScrollToError />
              <Field
                type={measurementInfo?.typeOfMeasurement}
                values={values}
                errors={errors}
                name="constructionSiteUuids"
                component={ConstructionSite}
              />
              <Field
                errors={errors}
                name="theBuildingIsMadeOf"
                component={BuildingMaterialComponent}
              />
              <SingleSectionElement
                touched={touched}
                name="plannedThickness"
                errors={errors}
              >
                <Field
                  label="Planowana grubość ocieplenia"
                  name="plannedThickness"
                  type="number"
                  rightPlaceholder="mm"
                  component={Input}
                />
              </SingleSectionElement>
              <Field
                type={measurementInfo?.typeOfMeasurement}
                errors={errors}
                name="installationElementsUuids"
                component={MountingAccessories}
              />
              <SingleSectionElement
                touched={touched}
                name="asdas"
                errors={errors}
                className="padding-top-separator static-alert-top"
              >
                <Field
                  label="Rodzaj montażu"
                  optional
                  name="asdas"
                  white
                  type="number"
                  rightPlaceholder="mm"
                  options={[
                    { value: "interior", label: "Stan surowy" },
                    { value: "external", label: "Budynek z elewacją" },
                  ]}
                  component={Select}
                />
              </SingleSectionElement>
              <SingleSectionElement
                className="single-section-element--margin-top"
                touched={touched}
                name="plannedAssemblyTime"
                errors={errors}
              >
                <Field
                  label="Planowany czas montażu (w godzinach)"
                  name="plannedAssemblyTime"
                  maxLength={6}
                  type="number"
                  rightPlaceholder="h"
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                className="single-section-element--margin-top"
                touched={touched}
                name="numberOfAssemblyTeams"
                errors={errors}
              >
                <Field
                  label="Ilość osób potrzebnych do montażu"
                  name="numberOfAssemblyTeams"
                  maxLength={3}
                  type="number"
                  component={Input}
                />
              </SingleSectionElement>
              <Field
                className="am-margin-top"
                errors={errors}
                label="Dodatkowe uwagi"
                name="comments"
                optional
                component={Textarea}
                maxLength={250}
              />
              <div className="am__buttons">
                <Button
                  stroke
                  onClick={() => prevStep()}
                  label="Poprzedni krok"
                />
                <Button onClick={() => handleSubmit()} label="Dalej" />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default BlindsAndShuttersInstallationInformation;
