import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { useMemo } from "react";

type OrderLabel = {
  label: string;
  value: string;
};

type UseGetOrderLabelsProps = {
  period: string;
};

export default function useGetOrderLabels({ period }: UseGetOrderLabelsProps) {
  const queryKey = useMemo(() => ["order-labels", period], [period]);

  return useQuery({
    queryKey,
    queryFn: async () => {
      const response = await axiosInstance.get(`/order-labels?period=${period}`);
      return response.data;
    },
    select: (data: { orderLabels: OrderLabel[] }) => data?.orderLabels,
    retry: false,
    staleTime: 0,
  });
}
